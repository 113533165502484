import React from "react";
import * as S from "./Image.styles";
import * as Types from "./Image.types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Base } from "./Image.styles";

export const Plugin = ({
  className = "",
  src,
  srcMobile,
  objectFit = "contain",
  gatsbyImageSrc = null,
  alt = "",
  heightImg,
  widthImg,
  component: ImageComponent = Base,
}: Types.IPropsGatsbyImage) => {
  if (gatsbyImageSrc) {
    return (
      <S.WrapperImage heightImg={heightImg} widthImg={widthImg}>
        <GatsbyImage
          className="wrapper-gatsby-image"
          image={gatsbyImageSrc}
          objectFit={objectFit}
          alt={alt}
        />
      </S.WrapperImage>
    );
  }

  return (
    <ImageComponent
      className={className}
      src={src}
      heightImg={heightImg}
      alt={alt}
    />
  );
};
