import React from "react";
import * as S from "./DoneBox.styles";

export const DoneBoxAnimated = React.memo(() => {
  return (
    <S.Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <S.Circle cx="26" cy="26" r="25" fill="none" />
      <S.Path
        className="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </S.Svg>
  );
});
