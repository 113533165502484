import React from "react";
import * as S from "./Footer.style";
import * as SVG from "../assets";

export const FooterSocialDesktop = () => (
  <>
    <S.WrapperSocial className="d-flex justify-content-between py-4">
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.instagram.com/coderspeak/"
      >
        <img src={SVG.Instagram} alt="" />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.linkedin.com/company/coderspeak"
      >
        <img src={SVG.Linkedin} alt="" />
      </a>
      <a
        className="pr-2"
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.facebook.com/coderspeak/"
      >
        <img src={SVG.Facebook} alt="" />
      </a>
    </S.WrapperSocial>
    <S.WrapperClutch className="mt-auto d-flex justify-content-between pt-5 mt-3 pb-4">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://clutch.co/profile/coderspeak"
      >
        <img src={SVG.Clutch} alt="" />
      </a>
    </S.WrapperClutch>
  </>
);
