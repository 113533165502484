import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { FooterSocialDesktop } from "./FooterSocial.desktop";
import { FooterSocialMobile } from "./FooterSocial.mobile";
import { useResize } from "@hooks";
import * as S from "./Footer.style";
import * as SH from "@shared";
import * as SVG from "../assets";

const Footer = () => {
  const { isMobile } = useResize();

  return (
    <footer>
      <SH.Wrapper.Primary paddingTop={{ desktop: 81, mobile: 41 }}>
        <Container>
          <Row>
            <Col lg={3} xl={2}>
              <img src={SVG.Logo} alt="" />
              <SH.Text.SubTitle1 className="py-3">
                quality, usability, performance
              </SH.Text.SubTitle1>
              {isMobile || <FooterSocialDesktop />}
            </Col>
            <Col xs="12" className="offset-lg-1 offset-xl-1" lg="5">
              <SH.Text.SubTitle2 colorComponent="lightblue">
                headquarters
              </SH.Text.SubTitle2>
              <Row>
                <Col xs="6">
                  <SH.Text.SubTitle3>USA</SH.Text.SubTitle3>
                  <SH.Text.SubTitle3>201 West 5th St 11th Floor<br/>
                    TX 78701 Austin, Texas, United States
                  </SH.Text.SubTitle3>
                </Col>
                <Col xs="6">
                  <SH.Text.SubTitle3>Europe branch</SH.Text.SubTitle3>
                  <SH.Text.SubTitle3>Szlak 50<br/>
                    31-153 Cracow, Poland
                  </SH.Text.SubTitle3>
                </Col>
              </Row>
            </Col>
            <Col className="mt-5 mt-lg-0" xs="6" lg={{ span: 1 }}>
              <nav>
                <SH.Text.SubTitle2 colorComponent="lightblue">
                  menu
                </SH.Text.SubTitle2>
                <ul className="list-unstyled">
                  <li className="pb-3">
                    <S.StyledLink to="/services">services</S.StyledLink>
                  </li>
                  <li className="pb-3">
                    <S.StyledLink to="/expertise">expertise</S.StyledLink>
                  </li>
                  <li className="pb-3">
                    <S.StyledLink to="/how-we-work">how we work</S.StyledLink>
                  </li>
                  <li className="pb-3">
                    <S.StyledLink to="/about-us">about us</S.StyledLink>
                  </li>
                  <li className="pb-3">
                    <S.StyledLink to="/careers">careers</S.StyledLink>
                  </li>
                  <li className="pb-3">
                    <S.StyledLink to="/resources">resources</S.StyledLink>
                  </li>
                </ul>
              </nav>
            </Col>
            <Col className="mt-5 mt-lg-0" xs="6" lg={{ span: 2 }}>
              <SH.Text.SubTitle2 colorComponent="lightblue">
                contact
              </SH.Text.SubTitle2>
              <SH.Text.SubTitle3>
                <S.Href
                  className="link-unstyled"
                  href="mailto:info@CodersPeak.com"
                >
                  info@CodersPeak.com
                </S.Href>
              </SH.Text.SubTitle3>
              <SH.Text.SubTitle3>
                <S.Href className="link-unstyled" href="tel:+48 690 128 648">
                  +48 690 128 648
                </S.Href>
              </SH.Text.SubTitle3>
            </Col>
          </Row>
          {isMobile && <FooterSocialMobile />}
          <Row className="justify-content-center">
            <S.LineBottom xs={11} lg={12} />
          </Row>
          <Row className="justify-content-between py-3">
            <Col xs="5" lg={{ span: 2, offset: 5 }}>
              <SH.Text.TextBody>
                © {new Date().getFullYear()} coderspeak
              </SH.Text.TextBody>
            </Col>
            <Col className="text-right" xs={3} lg={{ span: 2 }}>
              <S.StyledLinkBody to="/cookies-policy">
                cookie policy
              </S.StyledLinkBody>
            </Col>
          </Row>
        </Container>
      </SH.Wrapper.Primary>
    </footer>
  );
};

export default Footer;
