import React from "react";
import { Helmet } from "react-helmet";
import useFetchDefaultSEO from "./SEO.hooks";
interface TFullSeo {
  metaTitle: string;
  metaDescription: string;
  shareImage: {
    localFile: {
      publicUrl: string;
    };
  };
}
interface Seo {
  seo: TFullSeo | {};
}

interface SEO {}
const SEO = ({ seo = {} }: Seo) => {
  const { data } = useFetchDefaultSEO();
  const { defaultSeo, siteName, favicon, faviconIco } = data.strapiGlobal;

  // Merge default and page-specific SEO values
  const fullSeo: TFullSeo = { ...defaultSeo, ...seo };

  const getMetaTags = () => {
    const tags = [];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: `${fullSeo.metaTitle} | ${siteName}`,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      );
    }
    if (fullSeo.shareImage) {
      const imageUrl = fullSeo.shareImage.localFile.publicUrl;
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      );
    }

    tags.push({ name: "twitter:card", content: "summary_large_image" });

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={fullSeo.metaTitle}
      titleTemplate={`%s | ${siteName}`}
      link={[
        {
          rel: "icon",
          href: favicon.localFile.publicUrl,
        },
        {
          rel: "alternate icon",
          href: faviconIco.localFile.publicUrl,
        },
      ]}
      meta={metaTags}
      script={[
        {
          id: "Cookiebot",
          src: "https://consent.cookiebot.com/uc.js",
          "data-cbid": "b38f09c8-9e51-443f-a126-5ea34bd9d4b7",
          "data-blockingmode": "auto",
          type: "text/javascript",
        },
      ]}
    />
  );
};

export default SEO;
