import { useStaticQuery, graphql } from "gatsby";

const useFetchDefaultSEO = () => {
  const data: TData = useStaticQuery(graphql`
    {
      strapiGlobal {
        siteName
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              publicUrl
            }
          }
        }
        favicon {
          localFile {
            publicUrl
          }
        }
        faviconIco {
          localFile {
            publicUrl
          }
        }
      }
    }
  `);

  return { data };
};

export default useFetchDefaultSEO;

type TData = {
  strapiGlobal: {
    siteName: string;
    defaultSeo: {
      metaTitle: string;
      metaDescription: string;
      shareImage: {
        localFile: {
          publicUrl: string;
        };
      };
    };
    favicon: {
      localFile: {
        publicUrl: string;
      };
    };
    faviconIco: {
      localFile: {
        publicUrl: string;
      };
    };
  };
};
