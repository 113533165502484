import styled, { css, keyframes } from "styled-components";
import * as theme from "@theme";

const load = keyframes`
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
`;

const LoaderCSS = css`
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: ${load} 1.8s infinite ease-in-out;
`;

export const Loader = styled.div`
  ${LoaderCSS}
  &::after,&::before {
    ${LoaderCSS}
  }

  color: ${theme.colors.lightblue};
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
  }
  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5em;
  }
`;
