import React from "react";
import ReactHtmlParser from "react-html-parser";
import * as SH from "@shared";
import { Row, Container, Col } from "react-bootstrap";
import { useFetchDefaultHeroImg } from "@hooks";
import scrollTo from "gatsby-plugin-smoothscroll";

export const HeroSection = ({
  title,
  description,
  bgHero = {},
  descriptionBig,
  buttonScroll,
}: THeroSectionProps) => {
  const { data } = useFetchDefaultHeroImg();
  const { defaultHero } = data.strapiGlobal;
  const fullHeroImg = { ...defaultHero, ...bgHero };

  return (
    <SH.Wrapper.HeroSection
      className="d-flex align-items-center pt-5 pt-lg-0"
      bgMobile={fullHeroImg.bgMobile.localFile.publicUrl}
      bgDesktop={fullHeroImg.bgDesktop.localFile.publicUrl}
    >
      <Container className="pt-5 pt-lg-0">
        <Row className="justify-content-center">
          <Col lg="5" className="text-center">
            <SH.Text.Header1>{title}</SH.Text.Header1>
            {description && (
              <SH.Wrapper.WrapperWysiwygHero>
                {ReactHtmlParser(description)}
              </SH.Wrapper.WrapperWysiwygHero>
            )}
            {descriptionBig && (
              <SH.Text.Header3 colorComponent="darkblue">
                {descriptionBig}
              </SH.Text.Header3>
            )}
            {buttonScroll && (
              <SH.Button.Base
                className="mt-5 mx-auto"
                onClick={() => scrollTo(`#${buttonScroll.scrollTo}`)}
              >
                {buttonScroll.text}
              </SH.Button.Base>
            )}
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.HeroSection>
  );
};

export type THeroSectionProps = {
  title: string;
  bgHero?: {};
  description?: string;
  descriptionBig?: string;
  buttonScroll?: {
    scrollTo: string;
    text: string;
  };
};
