import styled, { css } from "styled-components";
import { Link } from "gatsby";
import * as SH from "@shared";
import * as theme from "@theme";
import { Container } from "react-bootstrap";

export const NavHref = styled(Link)`
  display: block;
  ${SH.Text.Header3Css};
  color: inherit;
  span {
    padding: 15px 5px;
    position: relative;
  }
  white-space: nowrap;
  font-weight: 500;
  &.active {
    color: ${theme.colors.lightblue};
    font-weight: 500;
  }
  @media ${theme.breakpoints.mobile} {
    padding-left: 30%;
  }
  @media ${theme.breakpoints.desktop} {
    ${SH.Text.SubTitle3Css}
  }
`;

export const CounterVacancies = styled.div`
  position: absolute;
  background: ${theme.colors.darkblue};
  color: ${theme.colors.white};
  vertical-align: -50%;
  top: 0;
  right: -1.5rem;
  font-weight: 600;
  width: 22px;
  height: 22px;
  line-height: 23px;
  border-radius: 50%;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
`;

//Desktop style start:
export const Nav = styled.nav<TNav>`
  position: fixed;
  padding: 5px 0;
  display: block;
  width: 100%;
  z-index: 9999;
  background-color: transparent;
  transition: transform 0.25s ease-in;
  &:after{
    position: absolute;
    left:0;
    top:0;
    content: " ";
    background: ${theme.colors.whitegray};
    
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
  }
  @media ${theme.breakpoints.desktop} {
    padding: 20px 0 10px;
  }
  
  ${({ offsetY }) =>
    offsetY &&
    offsetY > 50 &&
    css`
      transform: translateY(-10px);
      padding-bottom: 10px;

      &:after {
        opacity: 1;
      }
    `}
  ${({ offsetY }) =>
    offsetY === undefined &&
    css`
      &:after {
        opacity: 1;
      }
    `}
  @media ${theme.breakpoints.mobile} {
    transform: translateY(0);
  }
`;

export const NavLi = styled.li`
  display: inline-block;
  margin-left: 20px;
  margin-left: clamp(10px, 2%, 50px);
  min-width: 42px;
`;

//Desktop style emd

//mobile style start
export const NavMobileWrapper = styled.div<TNavMobileWrapper>`
  z-index: 9999999999;
  position: fixed;
  width: 100vw;
  height: ${({ heightComponent }) => `${heightComponent}px`};
  ${() => ` background: linear-gradient(to right, ${theme.colors.white} 80%, ${theme.colors.whitegray} 20%);
  left: 0;`}
  top: 0;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-2000px);
  padding: 5px 20px 15px;
  ${({ heightComponent }) =>
    heightComponent === 0 &&
    css`
      height: 100vh;
    `}
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      transform: translateX(0);
    `}
`;

export const NavMobileUl = styled.ul`
  height: 50vh;
  max-height: 400px;
  padding-bottom: 10vh;
  padding-right: 17%;
  @media (orientation: landscape) {
    overflow-y: scroll;
  }
`;

const Hambugercss = css`
  width: 22.5px;
  height: 2.5px;
  background-color: ${theme.colors.blueblack};
`;

export const HambugerWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Hamburger = styled.div`
  ${Hambugercss}
  position: relative;

  &::before,
  &::after {
    position: absolute;
    ${Hambugercss}
    content: "";
  }
  &::before {
    top: -6.5px;
  }

  &::after {
    top: 6.5px;
  }
`;

export const Close = styled.div`
  position: absolute;

  width: 25px;
  height: 25px;
  margin-left: 4px;
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 26px;
    width: 2px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const ContainerClose = styled(Container)`
  position: absolute;
`;
//mobile style end

type TNav = {
  offsetY?: number;
};

type TNavMobileWrapper = {
  isMenuOpen: boolean;
  heightComponent: number;
};
