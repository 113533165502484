import React, { useState, useEffect } from "react";
import { debounce } from "ts-debounce";

export const useResize = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidthWindow] = useState(1);
  const [height, setHeightWindow] = useState(1000);

  useEffect(() => {
    function handleResize() {
      setWidthWindow(window.innerWidth);
      setHeightWindow(window.innerHeight);
    }
    handleResize();
    const debouncedFunction = debounce(handleResize, 100);
    window.addEventListener("resize", debouncedFunction);

    return () => {
      window.removeEventListener("resize", debouncedFunction);
      debouncedFunction.cancel();
    };
  }, []);

  useEffect(() => {
    width >= 991 ? setIsMobile(false) : setIsMobile(true);
  }, [width]);

  return { width, isMobile, height };
};
