import React from "react";
import GlobalStyle from "./GlobalStyle";
import Footer from "./Footer";
import Nav from "./Navigation";
import SEO from "./SEO";
import CookieBot from "./CookieBot";

const Layout = ({ children, seo = {} }: TLayoutProps) => {
  return (
    <>
      <GlobalStyle />
      <SEO seo={seo} />
      <Nav />
      <main>{children}</main>
      <Footer />
      <CookieBot />
    </>
  );
};

export default Layout;

type TLayoutProps = {
  readonly children: React.ReactNode;
  seo?:
    | {}
    | {
        metaTitle: string;
        metaDescription: string;
        shareImage: {
          localFile: {
            publicUrl: string;
          };
        };
      };
};
