import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import * as S from "./Nav.styles";
import * as SH from "@shared";
import { LogoMobile } from "../assets";

export const NavMobile = ({
  vacancies = 0,
  height = 0,
  isVacanciesOpen = true,
}) => {
  const [isMenuOpen, toggleIsMenuOpen] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body)
      isMenuOpen ? body.classList.add("lock") : body.classList.remove("lock");
  }, [isMenuOpen]);
  return (
    <>
      <Container className="d-lg-none">
        <Row className="justify-content-between align-items-center">
          <Col xs="6" lg="3">
            <Link to="/">
              <img src={LogoMobile} alt="" />
            </Link>
          </Col>
          <Col xs="6" lg="3" className="d-flex justify-content-end px-0">
            <S.HambugerWrapper onClick={() => toggleIsMenuOpen(!isMenuOpen)}>
              <S.Hamburger />
            </S.HambugerWrapper>
          </Col>
        </Row>
      </Container>

      <S.NavMobileWrapper
        heightComponent={height}
        className="d-lg-none d-flex flex-column justify-content-between"
        isMenuOpen={isMenuOpen}
      >
        <Row className="justify-content-between align-items-center">
          <Col xs="6" lg="3">
            <Link to="/">
              <img src={LogoMobile} alt="" />
            </Link>
          </Col>
          <Col xs="6" lg="3" className="d-flex justify-content-end px-0">
            <S.HambugerWrapper onClick={() => toggleIsMenuOpen(!isMenuOpen)}>
              <S.Close onClick={() => toggleIsMenuOpen(!isMenuOpen)} />
            </S.HambugerWrapper>
          </Col>
        </Row>

        <S.NavMobileUl className="d-flex flex-column list-unstyled justify-content-between my-auto">
          <S.NavLi>
            <S.NavHref
              onClick={() => toggleIsMenuOpen(!isMenuOpen)}
              activeClassName="active"
              to="/services"
            >
              <span> services</span>
            </S.NavHref>
          </S.NavLi>
          <S.NavLi>
            <S.NavHref
              onClick={() => toggleIsMenuOpen(!isMenuOpen)}
              activeClassName="active"
              to="/expertise"
            >
              <span> expertise</span>
            </S.NavHref>
          </S.NavLi>
          <S.NavLi>
            <S.NavHref
              onClick={() => toggleIsMenuOpen(!isMenuOpen)}
              activeClassName="active"
              to="/how-we-work"
            >
              <span> how we work</span>
            </S.NavHref>
          </S.NavLi>
          <S.NavLi>
            <S.NavHref
              onClick={() => toggleIsMenuOpen(!isMenuOpen)}
              activeClassName="active"
              to="/about-us"
            >
              <span> about us</span>
            </S.NavHref>
          </S.NavLi>
          <S.NavLi>
            <S.NavHref
              onClick={() => toggleIsMenuOpen(!isMenuOpen)}
              activeClassName="active"
              to="/careers"
            >
              <span>
                careers
                {isVacanciesOpen && (
                  <S.CounterVacancies>{vacancies - 1}</S.CounterVacancies>
                )}
              </span>
            </S.NavHref>
          </S.NavLi>
          <S.NavLi>
            <S.NavHref
              onClick={() => toggleIsMenuOpen(!isMenuOpen)}
              activeClassName="active"
              to="/resources"
            >
              <span> resources</span>
            </S.NavHref>
          </S.NavLi>
          <S.NavLi>
            <SH.Button.Nav
              onClick={() => toggleIsMenuOpen(!isMenuOpen)}
              to="/contact"
            >
              contact us
            </SH.Button.Nav>
          </S.NavLi>
        </S.NavMobileUl>
      </S.NavMobileWrapper>
    </>
  );
};
