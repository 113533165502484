export const fonts = {
  RedHatDisplay: "'Red Hat Display', sans-serif",
  fontSize: {
    desktop: {
      xxxs: "1.2rem",
      xxs: "1.3rem",
      xs: "1.4rem",
      s: "1.5rem",
      m: "2.2rem",
      l: "2.5rem",
      xl: "4.0rem",
      xxl: "5rem",
    },
    mobile: {
      xxxs: "1.0rem",
      xxs: "1.1rem",
      xs: "1.4rem",
      s: "1.4rem",
      m: "1.8rem",
      l: "2rem",
      xl: "2.8rem",
      xxl: "3.2rem",
    },
  },
  lineHeight: {
    desktop: {
      xxxs: "1.7rem",
      xxs: "2.2rem",
      xs: "2.2rem",
      s: "2.4rem",
      m: "3.3rem",
      l: "3.5rem",
      xl: "5.2rem",
      xxl: "6.1rem",
    },
    mobile: {
      xxxs: "1.4rem",
      xxs: "1.9rem",
      xs: "2.2rem",
      s: "2.2rem",
      m: "2.3rem",
      l: "2.6rem",
      xl: "3.6rem",
      xxl: "3.9rem",
    },
  },
  fontWeight: {
    normal400: 400,
    medium500: 500,
    semibold600: 600,
    bold700: 700,
    extrabold900: 900,
  },
};
