import React from "react";
import * as SH from "@shared";
import * as S from "./Nav.styles";
import { Link } from "gatsby";
import { Logo } from "../assets";
import { Container, Row, Col } from "react-bootstrap";

export const NavDesktop = ({ vacancies = 0, isVacanciesOpen = true }) => {
  return (
    <Container className="d-none d-lg-block">
      <Row className="justify-content-between align-items-center">
        <Col lg="3">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </Col>
        <Col lg="9">
          <ul className="d-flex justify-content-end list-unstyled align-items-center mb-0">
            <S.NavLi>
              <S.NavHref activeClassName="active" to="/services">
                <span> services</span>
              </S.NavHref>
            </S.NavLi>
            <S.NavLi>
              <S.NavHref activeClassName="active" to="/expertise">
                <span> expertise</span>
              </S.NavHref>
            </S.NavLi>
            <S.NavLi>
              <S.NavHref activeClassName="active" to="/how-we-work">
                <span> how we work</span>
              </S.NavHref>
            </S.NavLi>
            <S.NavLi>
              <S.NavHref activeClassName="active" to="/about-us">
                <span> about us</span>
              </S.NavHref>
            </S.NavLi>
            <S.NavLi>
              <S.NavHref activeClassName="active" to="/careers">
                <span>
                  careers
                  {isVacanciesOpen && (
                    <S.CounterVacancies>{vacancies - 1}</S.CounterVacancies>
                  )}
                </span>
              </S.NavHref>
            </S.NavLi>
            <S.NavLi>
              <S.NavHref activeClassName="active" to="/resources">
                <span> resources</span>
              </S.NavHref>
            </S.NavLi>
            <S.NavLi>
              <SH.Button.Nav
                className="d-flex align-items-center justify-content-center"
                to="/contact"
              >
                contact us
              </SH.Button.Nav>
            </S.NavLi>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};
