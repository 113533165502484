import styled, { css } from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import * as theme from "@theme";
import { IImage } from "./Image.types";

export const Image = styled.img<IImage>`
  width: ${({ widthImg }) => (widthImg ? `${widthImg.mobile}px` : `auto`)};
  max-height: ${({ heightImg }) =>
    heightImg ? `${heightImg.mobile}px` : `auto`};
  height: auto;
  object-fit: contain;
  max-width: 100%;
  @media ${theme.breakpoints.desktop} {
    width: ${({ widthImg }) => (widthImg ? `${widthImg.desktop}px` : `auto`)};
    height: auto;
    max-height: ${({ heightImg }) =>
      heightImg ? `${heightImg.desktop}px` : `auto`};
  }
`;

export const WrapperImage = styled.div<IImage>`
  max-width: 100%;
  height: ${({ heightImg }) => (heightImg ? `${heightImg.mobile}px` : `100%`)};
  width: 100%;
  @media ${theme.breakpoints.desktop} {
    height: ${({ heightImg }) =>
      heightImg ? `${heightImg.desktop}px` : `100%`} !important;
  }
  .wrapper-gatsby-image {
    margin: 0 auto;
  }
  .wrapper-gatsby-image,
  picture {
    height: ${({ heightImg }) =>
      heightImg ? `${heightImg.mobile}px` : `100%`} !important;
    width: ${({ widthImg }) =>
      widthImg ? `${widthImg.mobile}px` : `100%`} !important;
    max-width: 100%;
    @media ${theme.breakpoints.desktop} {
      height: ${({ heightImg }) =>
        heightImg ? `${heightImg.desktop}px` : `100%`} !important;
      width: ${({ widthImg }) =>
        widthImg ? `${widthImg.desktop}px` : `100%`} !important;
      max-height: ${({ heightImg }) =>
        heightImg ? `${heightImg.desktop}px` : `100%`} !important;
    }
  }
`;

export const Base = styled.img<IImage>`
  width: ${({ widthImg }) => (widthImg ? `${widthImg.mobile}px` : `auto`)};
  max-height: ${({ heightImg }) =>
    heightImg ? `${heightImg.mobile}px` : `auto`};
  height: auto;
  object-fit: contain;
  max-width: 100%;
  @media ${theme.breakpoints.desktop} {
    width: ${({ widthImg }) => (widthImg ? `${widthImg.desktop}px` : `auto`)};
    height: auto;
    max-height: ${({ heightImg }) =>
      heightImg ? `${heightImg.desktop}px` : `auto`};
  }
`;
