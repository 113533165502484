export const colors: TColor = {
  whiteblue: "#F7FAFC",
  whitegray: "#EFF3FB",
  lightblue: "#4299E1",
  darkblue: "#2B6CB0",
  blueblack: "#2D3748",
  orange: "#F6AD55",
  yellow: "#F6E05E",
  white: "#FFF",
};

export type TColor = {
  whiteblue: string;
  whitegray: string;
  lightblue: string;
  darkblue: string;
  blueblack: string;
  orange: string;
  yellow: string;
  white: string;
};
