import { useStaticQuery, graphql } from "gatsby";

export const useFetchDefaultHeroImg = () => {
  const data: TData = useStaticQuery(graphql`
    {
      strapiGlobal {
        defaultHero {
          bgDesktop {
            localFile {
              publicUrl
            }
          }
          bgMobile {
            localFile {
              publicUrl
            }
          }
        }
      }
    }
  `);

  return { data };
};

type TData = {
  strapiGlobal: {
    defaultHero: {
      bgDesktop: {
        localFile: {
          publicUrl: string;
        };
      };
      bgMobile: {
        localFile: {
          publicUrl: string;
        };
      };
    };
  };
};
