import styled, { css } from "styled-components";
import * as theme from "@theme";
import { IColor } from "@types";

export const BaseText = css<IColor>`
  color: ${({ colorComponent }) =>
    colorComponent ? theme.colors[colorComponent] : theme.colors.blueblack};
`;

export const Header1Css = css`
  font-size: ${theme.fonts.fontSize.mobile.xxl};
  line-height: ${theme.fonts.lineHeight.mobile.xxl};
  font-weight: ${theme.fonts.fontWeight.extrabold900};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.desktop.xxl};
    line-height: ${theme.fonts.lineHeight.desktop.xxl};
  }
`;
export const Header2Css = css`
  font-size: ${theme.fonts.fontSize.mobile.xl};
  line-height: ${theme.fonts.lineHeight.mobile.xl};
  font-weight: ${theme.fonts.fontWeight.bold700};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.desktop.xl};
    line-height: ${theme.fonts.lineHeight.desktop.xl};
  }
`;
export const Header3Css = css`
  font-size: ${theme.fonts.fontSize.mobile.xl};
  line-height: ${theme.fonts.lineHeight.mobile.l};
  font-weight: ${theme.fonts.fontWeight.semibold600};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.desktop.l};
    line-height: ${theme.fonts.lineHeight.desktop.l};
  }
`;
export const SubTitle1Css = css`
  font-size: ${theme.fonts.fontSize.mobile.m};
  line-height: ${theme.fonts.lineHeight.mobile.m};
  font-weight: ${theme.fonts.fontWeight.normal400};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.desktop.m};
    line-height: ${theme.fonts.lineHeight.desktop.m};
  }
`;
export const SubTitle2Css = css`
  font-size: ${theme.fonts.fontSize.mobile.s};
  line-height: ${theme.fonts.lineHeight.mobile.s};
  font-weight: ${theme.fonts.fontWeight.bold700};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.mobile.s};
    line-height: ${theme.fonts.lineHeight.mobile.s};
  }
`;

export const SubTitle3Css = css`
  font-size: ${theme.fonts.fontSize.mobile.xs};
  line-height: ${theme.fonts.lineHeight.mobile.xs};
  font-weight: ${theme.fonts.fontWeight.medium500};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.desktop.xs};
    line-height: ${theme.fonts.lineHeight.desktop.xs};
  }
`;

export const TextBodyCss = css`
  font-size: ${theme.fonts.fontSize.mobile.s};
  line-height: ${theme.fonts.lineHeight.mobile.s};
  font-weight: ${theme.fonts.fontWeight.normal400};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.desktop.s};
    line-height: ${theme.fonts.lineHeight.desktop.s};
  }
`;

export const TextButtonCss = css`
  font-size: ${theme.fonts.fontSize.mobile.xxs};
  line-height: ${theme.fonts.lineHeight.mobile.xxs};
  font-weight: ${theme.fonts.fontWeight.medium500};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.desktop.xxs};
    line-height: ${theme.fonts.lineHeight.desktop.s};
  }
`;

export const Header1 = styled.h1`
  ${BaseText};
  ${Header1Css}
`;

export const Header2 = styled.h2`
  ${BaseText};
  ${Header2Css};
`;

export const Header3 = styled.h3`
  ${Header3Css}
  ${BaseText};
`;

export const SubTitle1 = styled.p`
  ${BaseText};
  ${SubTitle1Css}
`;

export const MarkdownSubTitle1 = styled.div`
  ${SubTitle1Css}
`;

export const SubTitle2 = styled.p`
  ${BaseText};
  ${SubTitle2Css};
`;

export const SubTitle3 = styled.p`
  ${BaseText};
  ${SubTitle3Css}
`;

export const TextBody = styled.p`
  ${BaseText};
  ${TextBodyCss}
`;

export const MarkdownTextBody = styled.div`
  ${TextBodyCss}
`;

export const TextButton = styled.p`
  ${BaseText};
  ${TextButtonCss};
`;

export const Overline = styled.p`
  ${BaseText};
  font-size: ${theme.fonts.fontSize.mobile.xxxs};
  line-height: ${theme.fonts.lineHeight.mobile.xxxs};
  font-weight: ${theme.fonts.fontWeight.normal400};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.desktop.xxxs};
    line-height: ${theme.fonts.lineHeight.desktop.xxxs};
  }
`;

export const Span = styled.span`
  ${BaseText};
`;

export const Vacancies = css`
  font-size: ${theme.fonts.fontSize.mobile.m};
  line-height: ${theme.fonts.lineHeight.mobile.m};
  font-weight: ${theme.fonts.fontWeight.normal400};

  @media ${theme.breakpoints.desktop} {
    font-size: ${theme.fonts.fontSize.desktop.l};
    line-height: ${theme.fonts.lineHeight.desktop.l};
  }
`;
