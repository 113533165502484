import React, { useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useResize, useScroll } from "@hooks";
import * as S from "./Nav.styles";
import { NavDesktop } from "./Nav.desktop";
import { NavMobile } from "./Nav.mobile";

const Nav = () => {
  const { scroll, setScroll } = useScroll(100);
  const { height } = useResize();
  const {
    allStrapiVacancies: { totalCount },
    strapiCareersPage: { isVacanciesOpen },
  }: TData = useStaticQuery(graphql`
    {
      allStrapiVacancies {
        totalCount
      }
      strapiCareersPage {
        isVacanciesOpen
      }
    }
  `);

  useEffect(() => {
    const { pageYOffset } = window;
    setScroll(() => ({
      y: pageYOffset + 1,
    }));
  }, []);

  return (
    <S.Nav offsetY={scroll.y}>
      <NavMobile
        height={height}
        vacancies={totalCount}
        isVacanciesOpen={isVacanciesOpen}
      />
      <NavDesktop vacancies={totalCount} isVacanciesOpen={isVacanciesOpen} />
    </S.Nav>
  );
};

export default Nav;

type TData = {
  allStrapiVacancies: {
    totalCount: number;
  };
  strapiCareersPage: {
    isVacanciesOpen: boolean;
  };
};
