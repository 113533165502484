import { createGlobalStyle } from "styled-components";
import * as theme from "@theme";
import "bootstrap/dist/css/bootstrap.min.css";

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html {
    font-size: 62.5%; 
  }
  
  body {
    overflow-x: hidden;
    font-size: 1.6rem;
    font-family: ${theme.fonts.RedHatDisplay};
    color: #2e2e2e;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    background-color: ${theme.colors.whiteblue};
    &.lock {
      height: 100%;
      overflow: hidden;
      width: 100%;
    }
  }
  body::-webkit-scrollbar {
    width: 0.5em;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  body::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  button {
    outline: none!important;
  }
  .container {
    @media (min-width: 992px){
      max-width: 95%;
    }
    @media (min-width: 1200px) {
      max-width: 1280px;
    }
  }
  #CybotCookiebotDialog{
    position: fixed;
    top: auto !important;
    bottom: 0 !important;
    .CybotCookiebotDialogBodyButton{
      border: ${theme.colors.darkblue};
      background-color: ${theme.colors.darkblue};
      border-radius: 10px;
      padding: 5px;
    }
  }
  div#hubspot-messages-iframe-container.widget-align-right{
    z-index:10000 !important;
  }
  
`;

export default GlobalStyle;
