import styled, { css } from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";
import Exclamation from "./exclamation-mark.svg";

export const Label = styled.label<{ isError: boolean; errorMessage?: string }>`
  width: 100%;
  position: relative;

  display: block;
  height: auto;
  &::before {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    border-bottom: 3px solid ${theme.colors.darkblue};
    padding-bottom: 10px;
    bottom: -52px;
    left: 0;
    transition: transform 0.5s ease-out;
    transform: translateX(-100%);
  }
  ${({ isError }) =>
    isError &&
    css`
      color: #dc3545;
      &::before {
        border-color: #dc3545;
        transform: translateX(0);
      }
      &:after {
        display: inline;
        content: "(required)";
        padding-left: 5px;
      }
    `}

  ${({ errorMessage = "required", isError }) =>
    isError &&
    errorMessage !== "" &&
    css`
      &:after {
        content: "(${errorMessage})";
      }
    `}
`;

export const BaseInput = styled.input`
  background: transparent none repeat scroll 0% 0%;
  position: relative;
  border-color: currentcolor currentcolor rgb(221, 221, 221);
  border-style: none none solid;
  border-width: medium medium 2px;
  border-image: none 100% / 1 / 0 stretch;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
  outline: none;
  width: 100%;

  &:focus + label::before {
    transform: translate(0);
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"] {
    appearance: textfield;
    -webkit-appearance: none;
  }
  &[type="file"] {
    display: none;
  }
`;

export const TextArea = styled.textarea`
  padding: 10px;
  resize: none;
  border: 2px solid rgb(221, 221, 221);
  height: 251px;
  margin: 25px 0 30px;
`;

export const TextAreaLabel = styled(Label)`
  height: auto;
  &:before {
    content: none;
  }

  &:after {
    left: 10%;
  }
`;

export const LabelFile = styled.label<{ isError: boolean }>`
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 2px solid #ddd;
  text-align: left;
  padding: 8px 0px;
  color: rgba(0, 0, 0, 0.53);
  font-size: 15px;
`;

export const LabelFileText = styled(SH.Text.TextBody)`
  display: block;
  text-align: left;
  top: -50px;
`;

export const LabelClause = styled.label<{ isError: boolean }>`
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 2px;
  }

  input:checked ~ .checkmark {
    background-color: #317ef1;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: none;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  ${({ isError }) =>
    isError &&
    css`
      color: #dc3545;
    `}
`;
