import React from "react";
import * as S from "./CookieBot.styles";

const CookieBotWrapper = () => {
  return (
    <S.Wrapper>
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/b38f09c8-9e51-443f-a126-5ea34bd9d4b7/cd.js"
        type="text/javascript"
        async
      ></script>
      <script
        type="text/plain"
        data-cookieconsent="statistics"
        src="/script/ga.js"
      ></script>
    </S.Wrapper>
  );
};

export default CookieBotWrapper;
