import styled, { css } from "styled-components";
import * as theme from "@theme";
import { IColor } from "@types";
import { IWrapper, THeroSectionProps, IBox } from "./Wrapper.types";
import * as SH from "@shared";

export const Primary = styled.div<IWrapper>`
  overflow-x: hidden;
  padding-top: ${({ paddingTop = { mobile: 105, desktop: 125 } }) =>
    `${paddingTop.mobile}px`};
  background-color: ${({ colorComponent = "white" }) =>
    theme.colors[colorComponent]};

  @media ${theme.breakpoints.desktop} {
    padding-top: ${({ paddingTop = { mobile: 105, desktop: 125 } }) =>
      `${paddingTop.desktop}px`};
  }
`;

export const Box = styled.div<IBox>`
  padding: 30px 20px;
  margin: 0 -15px;
  margin-bottom: 90px;
  height: ${({ heightComponent }) =>
    heightComponent?.mobile ? `${heightComponent.mobile}px` : `auto`};
  background-color: ${({ colorComponent = "white" }) =>
    theme.colors[colorComponent]};

  &:nth-of-type(even) {
    background-color: ${theme.colors.whitegray};
    .row-box {
      flex-direction: row-reverse !important;
    }
  }

  @media ${theme.breakpoints.desktop} {
    height: ${({ heightComponent }) =>
      heightComponent?.desktop ? `${heightComponent.desktop}px` : `auto`};
    padding: 40px 30px;

    &:nth-of-type(even) {
      padding: 40px 0;
    }
  }
`;

export const HeroSection = styled.div<THeroSectionProps>`
  background-color: ${theme.colors.whitegray};
  background-repeat: no-repeat;
  background-size: cover;
  ${({ bgMobile }) => `background-image: url(${bgMobile})`};
  min-height: 540px;

  @media ${theme.breakpoints.desktop} {
    ${({ bgDesktop }) => `background-image: url(${bgDesktop})`};
    min-height: 525px;
  }
`;

export const HeroSectionHome = styled(HeroSection)`
  min-height: 630px;
  padding-top: 105.5px;
  @media ${theme.breakpoints.desktop} {
    min-height: 640px;
  }
`;

export const WrapperWysiwyg = styled.div`
  .ck-heading_textbody,
  p {
    ${SH.Text.TextBodyCss};
  }
  .ck-heading_heading1,
  h1 {
    ${SH.Text.Header1Css};
  }
  .ck-heading_heading2,
  h2 {
    ${SH.Text.Header2Css};
  }
  .ck-heading_heading3,
  h3 {
    ${SH.Text.Header3Css};
  }
  .ck-heading_text-subtitle1 {
    ${SH.Text.SubTitle1Css};
  }
  .ck-heading_text-subtitle2 {
    ${SH.Text.SubTitle2Css};
  }
  .ck-heading_text-subtitle3 {
    ${SH.Text.SubTitle3Css};
  }
  blockquote {
    max-width: 610px;
    margin: 0 auto;
  }
  figure {
    text-align: center;
    max-width: 100%;
  }
  figure img {
    object-fit: contain;
    border: 20px solid ${theme.colors.whitegray};
    max-width: 100%;
  }
  .ck-heading_blue {
    color: ${theme.colors.darkblue};
  }
`;

export const WrapperWysiwygHero = styled(WrapperWysiwyg)`
  padding-top: 20px;
  p i {
    color: ${theme.colors.darkblue};
    font-style: normal;
  }
`;
