import styled from "styled-components";
import { Col } from "react-bootstrap";
import * as theme from "@theme";
import { Link } from "gatsby";
7;
import * as SH from "@shared";

export const LineBottom = styled(Col)`
  border-bottom: 2px solid ${theme.colors.darkblue};
`;

export const Href = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

export const StyledLink = styled(Link)`
  ${SH.Text.SubTitle3Css};

  color: inherit;
  &:hover {
    color: inherit;
  }
`;
export const StyledLinkBody = styled(StyledLink)`
  ${SH.Text.TextBodyCss};
`;

export const WrapperSocial = styled.div`
  max-width: 174px;
  a {
    display: block;
  }
`;

export const WrapperClutch = styled.div`
  max-width: 198px;
`;
