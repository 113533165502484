import React from "react";
import { Row, Col } from "react-bootstrap";
import * as S from "./Footer.style";
import * as SVG from "../assets";

export const FooterSocialMobile = () => (
  <Row className="py-3">
    <Col xs="6">
      <S.WrapperSocial className="d-flex justify-content-between">
        <a
          href="https://www.instagram.com/coderspeak/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={SVG.Instagram} alt="" />
        </a>
        <a
          href="https://www.linkedin.com/company/coderspeak/?originalSubdomain=pl"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={SVG.Linkedin} alt="" />
        </a>
        <a
          href="https://www.facebook.com/coderspeak/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={SVG.Facebook} alt="" />
        </a>
      </S.WrapperSocial>
    </Col>
    <Col className="d-flex justify-content-between" xs="6">
      <S.WrapperClutch className="d-flex justify-content-between">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://clutch.co/profile/coderspeak"
        >
          <img src={SVG.Clutch} alt="" />
        </a>
      </S.WrapperClutch>
    </Col>
  </Row>
);
