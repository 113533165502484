import styled, { css } from "styled-components";
import { Link } from "gatsby";
import * as theme from "@theme";
import { TextButtonCss } from "../Text/Text.styles";

const BaseCss = css`
  ${TextButtonCss};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.white};
  background: ${theme.colors.darkblue};
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  border: 0;
  width: 255px;
  height: 38px;
  line-height: 38px;
  border-radius: 20px;
  padding: 0;
  border: 2px solid ${theme.colors.darkblue};
  text-align: center;
  outline: none;
  z-index: 2;
  transition: color 0.5s;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  will-change: color;
  &:before {
    content: "";
    position: absolute;
    background: ${theme.colors.darkblue};

    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  &:after {
    position: absolute;
    transition: transform 0.5s;
    will-change: transform;
    content: "";
    width: 120%;
    left: 50%;
    bottom: -2px;
    height: 3px;
    background: ${theme.colors.white};
    height: 120%;
    left: -130%;
    transform: skewX(15deg);
    z-index: -1;
  }

  @media ${theme.breakpoints.mobile} {
    &:hover {
      color: ${theme.colors.darkblue};
      &:before {
        background: ${theme.colors.white};
      }
    }
  }

  @media ${theme.breakpoints.desktop} {
    &:hover {
      text-decoration: none;
      cursor: pointer;
      color: ${theme.colors.darkblue};

      &:after {
        transform: translateX(100%);
      }
    }
    width: 298px;
    height: 48px;
    line-height: 4.8rem;
  }
`;

export const Base = styled.button`
  ${BaseCss};
`;

export const BaseUpload = styled.span`
  ${BaseCss};
  width: 80px;
  background: ${theme.colors.lightblue};
  position: absolute;
  bottom: 0;
  right: -20px;
  border-bottom-left-radius: 0;

  border-color: ${theme.colors.lightblue};
  &:before {
    background: ${theme.colors.lightblue};
  }
  @media ${theme.breakpoints.desktop} {
    order-bottom-right-radius: 0;
    width: 123px;
    right: -50px;
  }
`;

export const Href = styled.a`
  ${BaseCss};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Nav = styled(Link)`
  ${BaseCss};
  width: 346px;
  height: 75px;
  line-height: 75px;
  font-size: ${theme.fonts.fontSize.mobile.xl};
  font-weight: ${theme.fonts.fontWeight.bold700};
  text-transform: lowercase;

  @media ${theme.breakpoints.mobile} {
    border-radius: 0 15px 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%;
  }

  @media ${theme.breakpoints.desktop} {
    margin-top: 3px;
    width: 85px;
    height: 24.42px;
    line-height: 24.42px;
    font-size: ${theme.fonts.fontSize.desktop.xxs};
  }
`;
